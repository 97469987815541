<template>
  <div class="full-height flex-column">
    <div
        class="mt-30 pa-10-20 full-height"
    >
      <div class="pa-20">
        <DatePicker class="mt-20" :type="'year'" :date="search_year" @click="setDate($event)"></DatePicker>
      </div>
      <div class="flex-row">
        <button class="filter_btn"
                @click="getSearch('')"
                :style="payment_state_code === '' ? 'color: #3E47B7' : 'color: #434343'"
        >전체
        </button>
        <button class="filter_btn"
                @click="getSearch('PM00400003')"
                :style="payment_state_code === 'PM00400003' ? 'color: #3E47B7' : 'color: #434343'"
        >결제완료
        </button>
        <button class="filter_btn"
                @click="getSearch('PM00400006')"
                :style="payment_state_code === 'PM00400006' ? 'color: #3E47B7' : 'color: #434343'"
        >결제취소
        </button>
      </div>
      <div
          class="mt-20"
          v-if="items.length > 0"
      >
        <ul
            class="size-px-14"
        >
          <li
              v-for="(history, h_index) in items"
              :key="'history_' + h_index"
              class="under-line-not-last pb-20 mb-20"
              @click="onDetail(history)"
          >
            <div
                class="justify-space-between items-center"
            >
              <div>구입처</div>
              <div
              >{{ history.sl_type_name }}
              </div>
            </div>
            <div
                class="justify-space-between items-center mt-10"
            >
              <div>결제상품</div>
              <div style="color: #3E47B7;"
              >{{ history.nft_sl_name }}
              </div>
            </div>
            <div
                class="justify-space-between items-center mt-10"
            >
              <div>결제수단</div>
              <div
              >{{ history.payment_mthd_name }}
              </div>
            </div>
            <div
                v-if="history.payment_mthd_code === 'PM01000003'"
                class="justify-space-between items-center mt-10"
            >
              <div>결제금액</div>
              <div>{{ Number(history.payment_amount) | makeComma(8) }}원</div>
            </div>
            <div
                v-else
                class="justify-space-between items-center mt-10"
            >
              <div>결제수량</div>
              <div>{{ history.payment_quantity | makeComma(8) }}</div>
            </div>
            <div
                v-if="history.payment_canceldate"
                class="justify-space-between items-center mt-10"
            >
              <div>취소일시</div>
              <div>{{ history.payment_canceldate }}</div>
            </div>
            <div
                v-else
                class="justify-space-between items-center mt-10"
            >
              <div>거래일시</div>
              <div>{{ history.pmtdate }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div
          v-else
          class="flex-column align-center justify-center"
          style="margin-top: -130px; height: calc(100% - 130px); padding-top: 150px;"
      >
        <div
            class="character3_none pt-130 text-center size-px-16" style="color: #BBB;"
        >결제된 내역이 없습니다.
        </div>
      </div>
    </div>

    <PopupLayer
        v-if="is_on_detail"
    >
      <template
          v-slot:body
      >
        <div
            class="bg-white pa-10-20 radius-20 full-height flex-column"
        >
          <div
              class="pa-10-20 under-line size-px-20 text-center color-blue-mafia font-weight-bold"
          >
            <span v-if="!item_detail.cancel_date">결제 상세 내역</span>
            <span v-else>취소 상세 내역</span>
          </div>

          <div
              class="mt-10 size-px-14 full-height flex-column overflow-y-auto" style="max-height: 550px;"
          >
            <div>
              <div class="under-line pb-10">
                <div class="size-px-16 font-weight-bold">정보</div>
                <div class="mt-10 justify-space-between">
                  <span>구입처</span>
                  <span>{{ item_payment.common_info.sl_type_name }}</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>결제상품</span>
                  <span style="color: #3E47B7;">{{ item_payment.common_info.nft_sl_name }}</span>
                </div>
              </div>
              <div class="mt-10 under-line pb-10">
                <div class="size-px-16 font-weight-bold">결제정보</div>
                <div class="mt-10 justify-space-between">
                  <span>거래번호</span>
                  <span>{{ item_detail.transaction_number }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.quantity">
                  <span>결제수단</span>
                  <span>{{ item_detail.methods }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.amount">
                  <span>카드</span>
                  <span>{{ item_detail.card_name }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.approval_number">
                  <span>승인번호</span>
                  <span>{{ item_detail.approval_number }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.installment">
                  <span>거래종류</span>
                  <span>{{ installment }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.quantity">
                  <span v-if="!item_detail.cancel_date">결제수량</span>
                  <span v-else>취소수량</span>
                  <span>{{ item_detail.quantity | makeComma(8) }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.amount">
                  <span v-if="!item_detail.cancel_date">결제금액</span>
                  <span v-else>취소금액</span>
                  <span>{{ item_detail.amount | makeComma }}원</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="!item_detail.cancel_date">
                  <span>할인</span>
                  <span>{{ item_detail.discount | makeComma(8) }}<span v-if="item_detail.amount">원</span></span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.last_quantity && !item_detail.cancel_date">
                  <span>최종결제수량</span>
                  <span style="color: #3E47B7;">{{ item_detail.last_quantity | makeComma(8) }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.last_amount && !item_detail.cancel_date">
                  <span>최종결제금액</span>
                  <span style="color: #3E47B7;">{{ item_detail.last_amount | makeComma }}원</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="!item_detail.cancel_date">
                  <span>거래일시</span>
                  <span>{{ item_detail.date }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.cancel_date">
                  <span>취소일시</span>
                  <span>{{ item_detail.cancel_date }}</span>
                </div>
              </div>
              <div class="mt-10 pb-10" v-if="item_detail.amount">
                <div class="size-px-16 font-weight-bold">판매자 정보</div>
                <div class="mt-10 justify-space-between">
                  <span>판매자상호</span>
                  <span>주식회사 메타쿱</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>대표자명</span>
                  <span>김우정</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>사업자등록번호</span>
                  <span>174811-0122632</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>주소</span>
                  <span>경상북도 경산시 둥지로 18길 19, 301호</span>
                </div>
              </div>
              <div class="mt-10 pb-10">
                <div class="size-px-16 font-weight-bold">NFT내역</div>
                <div class="mt-10 justify-space-between">
                  <span>NFT</span>
                  <div class="overflow-y-auto" style="max-height: 100px; width: 50%; text-align: end;">
                    <p v-for="(item, index) in item_detail.nft_list"
                       :key="'item_' + index"
                       style="text-decoration-line: underline;"
                    >
                      {{ item.cartl_nft_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div
              class="mt-auto btn_area pt-20"
          >
            <button
                class="btn_l btn_fill_blue"
                @click="offPayment"
            >확인
            </button>
          </div>
        </div>
      </template>
    </PopupLayer>
  </div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";

export default {
  name: 'NftHistory'
  , components: {DatePicker, PopupLayer}
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: 'nft payment history'
        , title: 'NFT 결제내역'
        , not_footer: true
        , type: 'mypage'
      }
      , items: []
      , item_payment: []
      , is_on_detail: false
      , item_detail: {
        transaction_number: ''
        , methods: ''
        , card_name: ''
        , amount: ''
        , quantity: ''
        , discount: ''
        , last_amount: ''
        , last_quantity: ''
        , date: ''
        , approval_number: ''
        , installment: ''
        , cancel_date: ''
        , state_code: ''
        , nft_list: []
      }
      , search_year: this.$date.init().year
      , payment_state_code: ''
    }
  }
  , computed: {
    installment: function () {
      let t = '-'
      if (this.item_detail.installment > 0) {
        t = this.item_detail.installment + '개월'
      } else {
        t = '일시불'
      }
      return t
    }
  }
  , methods: {
    getData: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_nft_payment_history
          , data: {
            member_number: this.user.member_number
            , search_year: this.search_year + ''
            , payment_state_code: this.payment_state_code
          }
          , type: true
        })

        if (result.success) {
          console.log(result.result.data.d.nft_payment_particulars_list, 'nft history')
          this.items = result.result.data.d.nft_payment_particulars_list
        } else {
          throw result.message
        }
      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , getSearch: function (data) {
      this.payment_state_code = data
      this.getData()
    }
    , resetDetail: function () {
      this.item_detail = {
        transaction_number: ''
        , methods: ''
        , card_name: ''
        , amount: ''
        , quantity: ''
        , discount: ''
        , last_amount: ''
        , last_quantity: ''
        , date: ''
        , approval_number: ''
        , installment: ''
        , cancel_date: ''
        , state_code: ''
        , nft_list: []
      }
    }
    , onDetail: async function (item) {
      this.resetDetail()
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_nft_payment_detail
          , data: {
            member_number: this.user.member_number
            , nft_payment_number: item.nft_payment_number
          }
          , type: true
        })

        if (result.success) {
          console.log(result.result.data.d)
          this.is_on_detail = true
          this.item_payment = result.result.data.d
          if (this.item_payment.coin_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.coin_payment_info.transaction_number
              , methods: this.item_payment.coin_payment_info.payment_mthd_name
              , discount: this.item_payment.coin_payment_info.dc_quantity
              , quantity: this.item_payment.coin_payment_info.payment_quantity
              , last_quantity: this.item_payment.coin_payment_info.last_payment_quantity
              , date: this.item_payment.coin_payment_info.payment_daytime
              , cancel_date: this.item_payment.coin_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.coin_payment_info.payment_state_code
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          } else if (this.item_payment.leglter_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.leglter_payment_info.transaction_number
              , methods: this.item_payment.leglter_payment_info.payment_mthd_name
              , card_name: this.item_payment.leglter_payment_info.card_name
              , discount: Number(this.item_payment.leglter_payment_info.dc_amount)
              , amount: Number(this.item_payment.leglter_payment_info.payment_amount)
              , last_amount: Number(this.item_payment.leglter_payment_info.last_payment_amount)
              , date: this.item_payment.leglter_payment_info.payment_daytime
              , cancel_date: this.item_payment.leglter_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.leglter_payment_info.payment_state_code
              , approval_number: this.item_payment.leglter_payment_info.approval_number
              , installment: this.item_payment.leglter_payment_info.installment_months_count
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          } else if (this.item_payment.point_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.point_payment_info.transaction_number
              , methods: this.item_payment.point_payment_info.payment_mthd_name
              , discount: Number(this.item_payment.point_payment_info.dc_quantity)
              , quantity: Number(this.item_payment.point_payment_info.payment_quantity)
              , last_quantity: Number(this.item_payment.point_payment_info.last_payment_quantity)
              , date: this.item_payment.point_payment_info.payment_daytime
              , cancel_date: this.item_payment.point_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.point_payment_info.payment_state_code
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          }
        } else {
          throw result.message
        }
      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , offPayment: function () {
      this.is_on_detail = false
      this.item_payment = {}
    }
    , setDate: function (date) {
      this.search_year = date
      this.getData()
    }
  }
  , created() {
    this.$emit('onLoad', this.program)
    this.getData()
  }
}
</script>
<style scoped>
.filter_btn {
  padding: 6px 14px;
  border-radius: 15px;
  background: #ECEDF8;
  color: #434343;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}
</style>
